import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StackGrid from "react-stack-grid";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import { COLORS } from "../utils/theme";

import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import { FullWidthContainer } from "../components/Layout/ContentContainers";

const iconColor = COLORS.red.hex;
class RestaurantsPage extends React.Component {
  getMetaUrl() {
    const { location } = this.props;
    const { pathname } = location;
    return `${siteConfig.siteUrl}${pathname}` || siteConfig.siteUrl;
  }

  render() {
    const pageTitle = `Local Eats`;
    const { data } = this.props;
    const restaurants = data.allWordpressWpRestaurants.edges.sort(
      (a, b) => a.node.title > b.node.title
    );
    return (
      <Page title="Local Eats">
        <Helmet title={`${pageTitle} | ${siteConfig.siteTitle}`}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta
            property="og:title"
            content={`${pageTitle} | ${siteConfig.siteTitle}`}
          />
        </Helmet>

        <FullWidthContainer>
          <StackGrid
            component="ul"
            itemComponent="li"
            columnWidth={360}
            gutterWidth={24}
            gutterHeight={30}
          >
            {restaurants
              .sort((a, b) => {
                const aTitle = a.node.title.toLowerCase();
                const bTitle = b.node.title.toLowerCase();
                if (aTitle > bTitle) {
                  return 1;
                }
                if (bTitle > aTitle) {
                  return -1;
                }
                return 0;
              })
              .map(edge => {
                const restaurant = edge.node;

                return (
                  <Card key={edge.node.id}>
                    {restaurant.acf.photo && (
                      <CardMedia
                        style={
                          { height: 0, paddingTop: "56.25%" } // 16:9
                        }
                        image={restaurant.acf.photo.sizes.medium}
                        title={restaurant.title}
                      />
                    )}
                    <CardContent style={{ background: "rgba(35, 31, 32, 0.92)" }}>
                      {restaurant.title && (
                        <RestaurantTitle
                          dangerouslySetInnerHTML={{
                            __html: restaurant.title
                          }}
                        />
                      )}
                      {(restaurant.acf.address || {}).address && (
                        <RestaurantAddress
                          dangerouslySetInnerHTML={{
                            __html: `<a target="_blank" href="https://maps.google.com/?q=${restaurant.acf.address.address}">${restaurant.acf.address.address}</a>`
                          }}
                        />
                      )}

                      {restaurant.content && (
                        <RestaurantContent
                          dangerouslySetInnerHTML={{
                            __html: restaurant.content
                          }}
                        />
                      )}
                      {restaurant.acf.hours && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Hours</h6> ${restaurant.acf.hours}`
                          }}
                        />
                      )}
                      {restaurant.acf.price_range && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Price range</h6>${restaurant.acf.price_range}`
                          }}
                        />
                      )}
                      {restaurant.acf.cuisine && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Cuisine</h6>${restaurant.acf.cuisine}`
                          }}
                        />
                      )}
                      {restaurant.acf.vegan_vegetarian_options && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Vegan / Vegetarian Options</h6>${restaurant.acf.vegan_vegetarian_options}`
                          }}
                        />
                      )}
                      {restaurant.acf.carnivore_options && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Carnivore Options</h6>${restaurant.acf.carnivore_options}`
                          }}
                        />
                      )}
                      {restaurant.acf.beer_liquor && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Beer / Liquor</h6>${restaurant.acf.beer_liquor}`
                          }}
                        />
                      )}
                      {restaurant.acf.thurs_mon_specials && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Thursday / Monday specials</h6>${restaurant.acf.thurs_mon_specials}`
                          }}
                        />
                      )}
                      {restaurant.acf.hosting_fest_shows && (
                        <RestaurantDetail
                          dangerouslySetInnerHTML={{
                            __html: `<h6>Hosting FEST Shows?</h6>${restaurant.acf.hosting_fest_shows}`
                          }}
                        />
                      )}
                    </CardContent>
                    {(restaurant.acf.link_facebook ||
                      restaurant.acf.link_instagram ||
                      restaurant.acf.link_twitter ||
                      restaurant.acf.link_yelp ||
                      restaurant.acf.link_custom_1) && (
                      <CardActions style={{ backgroundColor: "#231f20" }}>
                        <CardActionsLinkContainer>
                          {restaurant.acf.link_facebook && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={restaurant.acf.link_facebook.url}
                              title={`${restaurant.title} | Facebook`}
                            >
                              <FontAwesomeIcon
                                icon={["fab", "facebook"]}
                                color={iconColor}
                                size="2x"
                              />
                            </a>
                          )}
                          {restaurant.acf.link_twitter && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={restaurant.acf.link_twitter.url}
                              title={`${restaurant.title} | Twitter`}
                            >
                              <FontAwesomeIcon
                                icon={["fab", "twitter"]}
                                color={iconColor}
                                size="2x"
                              />
                            </a>
                          )}
                          {restaurant.acf.link_instagram && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={restaurant.acf.link_instagram.url}
                              title={`${restaurant.title} | Instagram`}
                            >
                              <FontAwesomeIcon
                                icon={["fab", "instagram"]}
                                color={iconColor}
                                size="2x"
                              />
                            </a>
                          )}
                          {restaurant.acf.link_yelp && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={restaurant.acf.link_yelp.url}
                              title={`${restaurant.title} | Yelp`}
                            >
                              <FontAwesomeIcon
                                icon={["fab", "yelp"]}
                                color={iconColor}
                                size="2x"
                              />
                            </a>
                          )}
                          {restaurant.acf.link_custom_1 && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={restaurant.acf.link_custom_1.url}
                              title={`${restaurant.acf.link_custom_1.url}`}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "external-link-alt"]}
                                color={iconColor}
                                size="2x"
                              />
                            </a>
                          )}
                        </CardActionsLinkContainer>
                      </CardActions>
                    )}
                  </Card>
                );
              })}
          </StackGrid>
        </FullWidthContainer>
      </Page>
    );
  }
}

export default RestaurantsPage;

const RestaurantTitle = styled.h4`
  color: #eee;
`;
const RestaurantAddress = styled.h5`
  font-size: 0.85rem;
  margin-bottom: 0.6rem;
`;
const RestaurantContent = styled.div`
  margin-top: 0.2rem;
  p {
    font-size: 1rem;
    line-height: 1.55rem;
    color: #ddd;
  }
`;

const RestaurantDetail = styled.div`
  p {
    font-size: 0.95rem;
    line-height: 1.55rem;
    color: #ddd;
  }
  h6 {
    color: #fff;
  }
`;

const CardActionsLinkContainer = styled.div`
  padding: 0.4rem 0;
  width: 100%;
  text-align: center;

  a {
    margin: 0 0.6rem;
  }
`;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query RestaurantsPageQuery {
    allWordpressWpRestaurants {
      edges {
        node {
          id
          title
          content
          acf {
            address {
              address
            }
            phone_number
            hours
            price_range
            cuisine
            vegan_vegetarian_options
            beer_liquor
            hosting_fest_shows
            thurs_mon_specials
            link_instagram {
              title
              url
              target
            }
            link_twitter {
              title
              url
              target
            }
            link_facebook {
              title
              url
              target
            }
            link_yelp {
              title
              url
              target
            }
            link_custom_1 {
              title
              url
              target
            }
          }
        }
      }
    }
  }
`;
/* 

            photo {
              url
              sizes {
                medium
              }
            }
            */
